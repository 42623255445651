import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { actionTypes } from "../../constants/actionTypes.js";
import { ASSETS_PATH, CURRENCY_CODES } from "../../constants/constants.js";
import { COOKIES } from "../../constants/cookies.js";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes.js";
import NomadsFetchRequests from "../../requests/index.js";
import { removeError, setError } from "../../store/actions/errors.js";
import { getCookie, getToken } from "../../utilities/cookie.js";
import SubButton from "../Common/Button/SubButton.jsx";
import SubComponentLoading from "../Common/Loading/ComponentLoading/SubComponentLoading.jsx";
import SubLoadingButton from "../Common/Loading/LoadingButton/SubLoadingButton.jsx";
import { translations } from "../../translations/texts.js";
import "react-datepicker/dist/react-datepicker.css";
import "./SmartTokenPurchase.scss";
import { useState } from "react";
import { useEffect } from "react";
import { getSubsriptionData } from "../../store/actions/subscription.js";
import SubPaymentMethods from "../PaymentMethods/SubPaymentMethods.jsx";
import { getPaymentProfiles } from "../../store/actions/user.js";
import SubTextInput from "../Common/Form/TextInput/TextInput.jsx";
import { getHeader } from "../../utilities/token.js";
import { changeToken } from "../../store/actions/tokenAmount.js";

const SmartTokenPurchase = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
	const cart = useSelector((state) => state.cart);
	const isLoggedIn = useSelector((state) => !!state.user.userData.id);
	const user = useSelector((state) => state.user.userData);
	const cardsArr = useSelector((state) => state.user.paymentMethods.creditCards);
	const token = useSelector((state) => state.changeToken.token);

	const [purchaseSuccess, setPurchaseSuccess] = useState(false);
	const [selectedCardIndex, setSelectedCardIndex] = useState(0);
	const [checkoutLoading, setCheckoutLoading] = useState(false);
	const [componentLoading, setComponentLoading] = useState(true);
	const [rentForMyself, setRentForMyself] = useState(false);
	const [subscriptionExpiryDate, setSubscriptionExpiryDate] = useState("");
	const [tokenPackages, setTokenPackages] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [tokenId, setTokenId] = useState(1)

	//update the on mount

	const onMount = () => {
		Promise.all([NomadsFetchRequests.nomadsAi.getTokenPackages()])
			.then((response) => {
				setTokenPackages(response[0].data);
				// setSubscriptionExpiryDate(profile.subscriptionExpiryDate);
			})
			.catch((error) => {
				dispatch(setError(error));
			})
			.finally(() => {
				setComponentLoading(false);
			});
	};

	useEffect(() => {
		onMount();

		return () => {
			dispatch(removeError);
		};
	}, []);

	const allowCheckout = () => {
		const userHasSelectedAPaymentMethod = cardsArr.length > 0;
		return userHasSelectedAPaymentMethod;
	};

	const handleSubSelect = (index, tokenId) => {
		setSelectedIndex(index);
		setTokenId(tokenId);
	};

	//this will be used for the token selection

	const renderTokenContent = () => {
		return (
			<table className="cart-page-table">
				<tbody>
					{tokenPackages.map((packages, index) => (
						<>
							<div
								onClick={() => handleSubSelect(index, packages.id)}
								className={`tokenOption ${selectedIndex === index ? "selected" : ""}`}
								key={index}
							>
								<span className="firstSpan">
									{packages.numTokens} {translations[selectedLanguage].common.token}
								</span>
								<span className="thirdSpan">
									${packages.price} {CURRENCY_CODES.USD}
								</span>
							</div>
						</>
					))}
				</tbody>
				<tfoot>
					<tr className="cart-page-table-total">
						<td>{translations[selectedLanguage].common.total}</td>
						<td className="cart-page-product-price">
							${getCartTotal()} {CURRENCY_CODES.USD}
						</td>
					</tr>
				</tfoot>
			</table>
		);
	};

	const buyTokens = async () => {
		try {
			const response = await NomadsFetchRequests.nomadsAi.buyTokens(getToken(), tokenId, cardsArr[selectedCardIndex].dataKey, tokenPackages[selectedIndex].price);
			dispatch(changeToken(token + tokenPackages[selectedIndex].numTokens))
			setPurchaseSuccess(true);
			setCheckoutLoading(false);
		} catch (error) {
			dispatch(setError(error));
			setCheckoutLoading(false);
		}
	};

	useEffect(() => {
		if (checkoutLoading) {
			if (cardsArr.length <= 0) {
				navigate(INTERNAL_ROUTES.PAYMENT.ADD_PAYMENT_PROFILE);
				return;
			}

			const productIds = [];

			cart.products.map((x) => productIds.push(x.id));
		}
	}, [checkoutLoading]);

	const cartCheckout = () => {
		setCheckoutLoading(true);
		buyTokens()
	};

	const getCartTotal = () => {
		return tokenPackages[selectedIndex].price.toFixed(2);
	};

	const changeSelectedCard = (index) => {
		setSelectedCardIndex(index);
	};

	const selectTokenAmount = () => {
		// setRentForMyself(true);
	};

	const renderDestinations = () => {
		return (
			<div className="cart-page-destinations">
				<div className="cart-page-destinations-choices">
					<div onClick={selectTokenAmount} className={`cart-page-destinations-choice ${rentForMyself ? " --selected" : ""}`}></div>
				</div>
			</div>
		);
	};

	const goToStore = () => {
		setPurchaseSuccess(false)
	};

	const renderToken = () => {
		if (purchaseSuccess) {
			return (
				<div>
					<h2>{translations[selectedLanguage].common.thankYouForPurchase}</h2>
					<div className="cart-page-wrap-exit-buttons">
						<SubButton onClick={goToStore} label={translations[selectedLanguage].common.store} />
					</div>
				</div>
			);
		}

		return (
			<>
				<h2>{translations[selectedLanguage].common.purchaseToken}</h2>
				{renderTokenContent()}
				{renderDestinations()}
				<SubPaymentMethods selectedCardIndex={selectedCardIndex} changeSelectedCard={changeSelectedCard} />
				<SubLoadingButton
					className="cart-page-checkout-button"
					isLoading={checkoutLoading}
					isDisabled={!allowCheckout()}
					onClick={cartCheckout}
					label="Checkout"
				/>
				<p className="cart-page-details">{translations[selectedLanguage].common.paymentWarning}</p>
			</>
		);
	};

	if (!isLoggedIn) {
		return <Navigate to={INTERNAL_ROUTES.USER.SIGN_IN} replace />;
	}

	return (
		<section data-testid="container-cart" className="cart-container" id="cart">
			<div className="cart-card">
				{componentLoading && <SubComponentLoading />}
				{!componentLoading && renderToken()}
			</div>
		</section>
	);
};

export default SmartTokenPurchase;
