import axios from "axios";
import { errorHandler } from "../utilities/errors";
import { getHeader, getHeaderAI } from "../utilities/token";
import { getToken } from "../utilities/cookie";

const support = {
	submitTicket: async (data, token = getToken()) => {
		return await axios
			.post(
				`${process.env.REACT_APP_SERVER_HOST}/support/submitticket`,
				{
					ticket: data
				},
				getHeaderAI(token)
			)
			.catch(errorHandler);
	},

	getTickets: async ( token = getToken()) => {
		return await axios
			.post(
				`${process.env.REACT_APP_SERVER_HOST}/support/gettickets`,
				{
					status: "open"
				},
				getHeader(token)
			)
			.catch(errorHandler);
	},
};

export default support;
