import React from "react";
import { translations } from "../../../../translations/texts";
import SubButton from "../../../Common/Button/SubButton.jsx";
import { useSelector } from "react-redux";
// import GoogleLogin from "react-google-login";
import { GoogleLogin } from "@react-oauth/google";
import NomadsFetchRequests from "../../../../requests/index.js";
import { setCookie } from "../../../../utilities/cookie";
import { COOKIES } from "../../../../constants/cookies.js";
import { signIn } from "../../../../store/actions/user.js";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { setError } from "../../../../store/actions/errors.js";
import "./SubSignInMehtods.scss";

const SubGoogleMethod = ({ isDisabled }) => {
	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
	const dispatch = useDispatch();

	const signInUser = (token) => {
		NomadsFetchRequests.account
			.getProfile(token)
			.then((response) => {
				const user = {
					id: response.data.id,
					firstName: response.data.firstName,
					lastName: response.data.lastName,
					gender: response.data.gender,
					dateOfBirth: response.data.dateofbirth | "",
					email: response.data.email,
					isSubscribed: response.data.isSubscribed,
					token,
				};

				dispatch(signIn(user));
			})
			.catch((error) => {
				// setLoginLoading(false);
				dispatch(setError(error));
			});
	};

	const submitHandler = async (e, credentialResponseDecoded) => {
		// e.preventDefault();

		if (credentialResponseDecoded && credentialResponseDecoded.email && credentialResponseDecoded.sub && credentialResponseDecoded.name) {
			NomadsFetchRequests.account
				.googleLogin(credentialResponseDecoded.email, credentialResponseDecoded.sub, credentialResponseDecoded.name)
				.then((response) => {
					signInUser(response.data.token);
					setCookie(COOKIES.TOKEN, response.data.token, 14);
				})
				.catch((error) => {
					dispatch(setError(error));
				});
		} else {
			console.error("Invalid credentialResponseDecoded:", credentialResponseDecoded);
		}
	};

	return (
		<section data-testid="container-cognito-redirect" className="extra-signin-methods">
			{/* <SubButton label="Google" isDisabled={isDisabled} /> */}
			<GoogleLogin
				onSuccess={(credentialResponse) => {
					const credentialResponseDecoded = jwtDecode(credentialResponse.credential);
					submitHandler(credentialResponse, credentialResponseDecoded);
				}}
				onError={() => {
					console.log("Login Failed");
				}}
			/>
		</section>
	);
};

export default SubGoogleMethod;
