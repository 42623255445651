export const helpCenter = {
  title: "Help Center",
  emailLabel: "Email",
  emailPlaceholder: "Enter your email",
  subjectLabel: "Subject",
  subjectPlaceholder: "Enter your subject",
  descriptionLabel: "Description",
  descriptionPlaceholder: "Enter your description",
  submitButton: "Submit",
  success: "Your ticket was submitted successfully, we will contact you by email shortly.",
};
