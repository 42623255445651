import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { actionTypes } from "../../constants/actionTypes";
import { ASSETS_PATH, CURRENCY_CODES } from "../../constants/constants.js";
import { COOKIES } from "../../constants/cookies";
import { INTERNAL_ROUTES } from "../../constants/internalRoutes.js";
import NomadsFetchRequests from "../../requests";
import { removeError, setError } from "../../store/actions/errors.js";
import { getCookie, getToken } from "../../utilities/cookie";
import SubButton from "../Common/Button/SubButton";
import SubComponentLoading from "../Common/Loading/ComponentLoading/SubComponentLoading";
import SubLoadingButton from "../Common/Loading/LoadingButton/SubLoadingButton";
import { translations } from "../../translations/texts";
import "react-datepicker/dist/react-datepicker.css";
import "./SmartSubscriptionDashboard.scss";
import { useState } from "react";
import { useEffect } from "react";
import { getSubsriptionData } from "../../store/actions/subscription";
import SubPaymentMethods from "../PaymentMethods/SubPaymentMethods.jsx";
import { getUserSubsriptionData } from "../../store/actions/userSubscription.js";

const SmartSubscriptionDashboard = () => {
	const selectedLanguage = useSelector((state) => state.language.selectedLanguage);
	const user = useSelector((state) => state.user.userData);
	const isLoggedIn = useSelector((state) => !!state.user.userData.id);
	const subscriptions = useSelector((state) => state.nomadsDb.subscriptions);
	const userSubscription = useSelector((state) => state.nomadsDb.userSubscription);
	const cardsArr = useSelector((state) => state.user.paymentMethods.creditCards);
	const [showCancelSubscriptionPrompt, setShowCancelSubscriptionPrompt] = useState(false);
	const [showChangeSubscriptionPrompt, setShowChangeSubscriptionPrompt] = useState(false);

	const [cancellationLoading, setCancellationLoading] = useState(false);
	const [componentLoading, setComponentLoading] = useState(true);
	const [expiryDate, setExpiryDate] = useState("");
	const [startDate, setStartDate] = useState("");
	const [recurringAmount, setRecurringAmount] = useState(0);
	const [nextBillingDate, setNextBillingDate] = useState("");
	const [months, setMonths] = useState(0);
	const [accountId, setAccountId] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [selectedCardIndex, setSelectedCardIndex] = useState(0);
	const [subscriptionId, setSubscriptionId] = useState(null);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		Promise.all([getSubsriptionData(), getUserSubsriptionData(), getUserInfo()])
			// .then(([subscriptionData, userSubscriptionData, userInfo]) => {
			// 	console.log("User Info:", userInfo);
			// })
			.catch((error) => {
				dispatch(setError(error));
			})
			.finally(() => {
				setComponentLoading(false);
			});

		return () => {
			dispatch(removeError);
		};
	}, []);

	
	const getUserInfo = () => {
		return new Promise((resolve, reject) => {
			NomadsFetchRequests.account
				.getProfile(getCookie(COOKIES.TOKEN))
				.then((response) => {
					const isSubscribed = response.data.isSubscribed;

					const result = {
						isSubscribed,
					};

					dispatch({
						type: actionTypes.UPDATE_USER,
						payload: result,
					});

					const expiryDate = response.data.subscriptionExpiryDate;
					const startDate = response.data.subscriptionStartDate;

					setStartDate(startDate || "");
					setExpiryDate(expiryDate || "");

					if (expiryDate || isSubscribed) {
						NomadsFetchRequests.account
							.getUserSubscription(getToken())
							.then((response) => {
								setAccountId(response.data.accountId);
								setNextBillingDate(response.data.nextBillingDate || "");
								setRecurringAmount(response.data.amount);
								setMonths(response.data.expiryDate);

								resolve();
							})
							.catch((error) => {
								dispatch(setError(error));
								reject(error);
							});
					} else {
						resolve();
					}
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	const showCancelSubscriptionPromptFunc = () => {
		setShowCancelSubscriptionPrompt(true);
	};

	const hideCancelSubscriptionPrompt = () => {
		setShowCancelSubscriptionPrompt(false);
	};

	const showChangeSubscriptionPromptFunc = () => {
		setShowChangeSubscriptionPrompt(true);
	};

	const hideChangeSubscriptionPrompt = () => {
		setShowChangeSubscriptionPrompt(false);
	};

	const handleSubSelect = (index, subscriptionId) => {
		setSelectedIndex(index);
		setSubscriptionId(subscriptionId);
	};

	const proceedWithCancelSubscription = async () => {
		setCancellationLoading(true);

		try {
			await NomadsFetchRequests.recurringPayment.cancelSubscription();
			setComponentLoading(true);
			setExpiryDate("");
			setStartDate("");
			setRecurringAmount(0);
			setNextBillingDate("");
			setMonths(0);

			hideCancelSubscriptionPrompt();
			getUserInfo().finally(() => {
				setCancellationLoading(false);
				setComponentLoading(false);
			});
			window.location.reload();
		} catch (error) {
			dispatch(setError(error));
			setCancellationLoading(false);
		}
	};

	const changeSelectedCard = (index) => {
		setSelectedCardIndex(index);
	};

	const proceedWithChangeSubscription = async () => {
		setComponentLoading(true);

		try {
			const response = await NomadsFetchRequests.recurringPayment.changeSubscription(cardsArr[selectedCardIndex].dataKey, subscriptionId);
			setComponentLoading(false);
			setShowChangeSubscriptionPrompt(false);
		} catch (error) {
			dispatch(setError(error));
			setComponentLoading(false);
		}
	};

	const goToSubscribe = () => {
		navigate(INTERNAL_ROUTES.USER.SUBSCRIBE);
	};

	const reactivateSubscription = async () => {
		setComponentLoading(true);

		try {
			const response = await NomadsFetchRequests.recurringPayment.reactivateSubscription(userSubscription.accountId);

			let result

			if (response.data.code === 200) {
				const isSubscribed = true;

				result = {
				isSubscribed,
				};

			}
			

			dispatch({
				type: actionTypes.UPDATE_USER,
				payload: result,
			});

			setComponentLoading(false);
			// });
			window.location.reload();

		} catch (error) {
			dispatch(setError(error));
			setComponentLoading(false);
		}
	};

	const renderSubscriptionSection = () => {
		return (
			<div className="subscription-info" id="subscription">
				<h2>{translations[selectedLanguage].userProfile.yourSubscription}</h2>
				<div className="user-profile-wrap-subscription-info">
					<div className="userprofile-card-item user-profile-status subscription-dashboard-status">
						<p className="subscription-dashboard-status-title">{`${translations[selectedLanguage].userProfile.status}: ${
							userSubscription.code === 500
								? ` ${translations[selectedLanguage].common.expiring.toUpperCase()}`
								: user.isSubscribed
								? ` ${translations[selectedLanguage].common.active.toUpperCase()}`
								: ` ${translations[selectedLanguage].common.inactive.toUpperCase()}`
						}`}</p>
						<img
							className="user-profile-subscription-img"
							src={userSubscription.code === 500 || !user.isSubscribed ? ASSETS_PATH.PROFILES.RED : ASSETS_PATH.PROFILES.GREEN}
							alt={translations[selectedLanguage].common.subImg}
						/>
					</div>
					{recurringAmount > 0 && (
						<p className="subscription-dashboard-recurring-amount-label">
							{`${translations[selectedLanguage].subscriptionDashboard.recurringAmount}: $${recurringAmount} ${CURRENCY_CODES.USD}`}
						</p>
					)}
					{nextBillingDate && <p>{`${translations[selectedLanguage].subscriptionDashboard.renewalDate}: ${nextBillingDate}`}</p>}
					{expiryDate && <p>{`${translations[selectedLanguage].subscriptionDashboard.expiryDate}: ${expiryDate}`}</p>}
				</div>
				<div className="subscription-dashboard-buttons">
					{user.isSubscribed && (
						<SubButton
							className="subscription-dashboard-cancel-button"
							onClick={showCancelSubscriptionPromptFunc}
							label={translations[selectedLanguage].subscriptionDashboard.cancelSub}
						/>
					)}
					{user.isSubscribed && (
						<SubButton
							className="subscription-dashboard-change-button"
							onClick={showChangeSubscriptionPromptFunc}
							label={translations[selectedLanguage].subscriptionDashboard.changeSub}
						/>
					)}
					{!user.isSubscribed && !expiryDate === 0 && (
						<SubButton onClick={goToSubscribe} label={translations[selectedLanguage].subscriptionDashboard.planAndPrices} />
					)}
					{!user.isSubscribed && userSubscription.code === 500 && (
						<SubButton onClick={reactivateSubscription} label={translations[selectedLanguage].subscriptionDashboard.reactivate} />
					)}
				</div>
			</div>
		);
	};

	const renderSubscriptionCancellationPrompt = () => {
		return (
			<div className="user-profile-modal-shadow-cloak">
				<div className="user-profile-modal-cancel-wrapper">
					<p className="user-profile-modal-title">{translations[selectedLanguage].userProfile.unsubConfirm}</p>
					<div className="user-profile-modal-confirm-button-wrap">
						<div className="subscription-dashboard-modal-confirm-button">
							<SubLoadingButton
								isLoading={cancellationLoading}
								label={translations[selectedLanguage].common.confirm}
								onClick={() => {
									proceedWithCancelSubscription();
								}}
							/>
						</div>
						<SubButton
							onClick={() => {
								hideCancelSubscriptionPrompt();
							}}
							label={translations[selectedLanguage].common.cancel}
						/>
					</div>
				</div>
			</div>
		);
	};

	const renderSubscriptionChangePrompt = () => {
		return (
			<div className="user-profile-modal-shadow-cloak">
				<div className="user-profile-modal-cancel-wrapper">
					<SubPaymentMethods selectedCardIndex={selectedCardIndex} changeSelectedCard={changeSelectedCard} />

					{subscriptions.map((subscription, index) => (
						<>
							<div
								onClick={() => handleSubSelect(index, subscription.id)}
								className={`subscriptionOption ${selectedIndex === index ? "selected" : ""}`}
								key={index}
							>
								<span className="firstSpan">
									{subscription.name}: {subscription.numGenerations} {translations[selectedLanguage].common.generations}{" "}
									{translations[selectedLanguage].common.amonthly}
								</span>
								{/* <span className="secondSpan">{translations[selectedLanguage].cart.fullLibraryAccess}</span> */}
								<span className="thirdSpan">
									${subscription.monthlyPrice} {CURRENCY_CODES.USD}
								</span>
							</div>
						</>
					))}

					<div className="user-profile-modal-confirm-button-wrap">
						<div className="subscription-dashboard-modal-confirm-button">
							<SubLoadingButton
								isLoading={cancellationLoading}
								label={translations[selectedLanguage].common.confirm}
								onClick={() => {
									proceedWithChangeSubscription();
								}}
							/>
						</div>
						<SubButton
							onClick={() => {
								hideChangeSubscriptionPrompt();
							}}
							label={translations[selectedLanguage].common.cancel}
						/>
					</div>
				</div>
			</div>
		);
	};

	const renderSubscription = () => {
		return (
			<>
				{!componentLoading && renderSubscriptionSection()}
				{showCancelSubscriptionPrompt ? renderSubscriptionCancellationPrompt() : null}

				{showChangeSubscriptionPrompt ? renderSubscriptionChangePrompt() : null}

				{/* {renderSubscriptionChange()} */}
			</>
		);
	};

	if (!isLoggedIn) {
		return <Navigate to={INTERNAL_ROUTES.USER.SIGN_IN} replace />;
	}

	return (
		<div data-testid="container-sub-dashboard" className="userprofile-container">
			<div className="userprofile-card subscription-dashboard">
				{componentLoading && <SubComponentLoading />}
				{!componentLoading && renderSubscription()}
			</div>
		</div>
	);
};

export default SmartSubscriptionDashboard;
