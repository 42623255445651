import NomadsFetchRequests from "../../requests";
import { actionTypes } from "../../constants/actionTypes";
import store from "../store";

export const getSubsriptionData = async () => {
  const response = await NomadsFetchRequests.recurringPayment.getAllSubscriptions();
  
  store.dispatch({
    type: actionTypes.SET_ALL_NOMADS_DATA,
    payload: {
      subscriptions: response.data,
    },
  });

  return response;
};
