import axios from "axios";
import { getToken } from "../../utilities/cookie";
import { errorHandler } from "../../utilities/errors";
import { getHeader, getHeaderAI } from "../../utilities/token";

const recurringPayment = {

  getAllSubscriptions: async () => {
    return await axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST_AI_OTHER}/dev/getsubscriptiontiers`,
        getHeaderAI()
      )
      .catch(errorHandler);
  },


  getUsersSubscription: async (token = getToken()) => {
    return await axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST_AI_OTHER}/dev/getusersubscription`,
        getHeaderAI(token)
      )
      .catch(errorHandler);
  },
  startSubscription: async (card, subscriptionId, email, token = getToken()) => {
    return await axios
    .post(
      `${process.env.REACT_APP_SERVER_HOST_AI_OTHER}/dev/startsubscription`,
      { 
        dataKey: card,
        subscriptionId: subscriptionId,
        userEmail: email
      },
      getHeaderAI(token)
    )
      .catch(errorHandler);
  },

  cancelSubscription: async (token = getToken()) => {
    return await axios
      .get(
        `${process.env.REACT_APP_SERVER_HOST_AI_OTHER}/dev/cancelsubscription`,
        getHeaderAI(token)
      )
      .catch(errorHandler);
  },

  reactivateSubscription: async (userid, token = getToken()) => {
    return await axios
    .post(
      `${process.env.REACT_APP_SERVER_HOST_AI_OTHER}/dev/reactivatesubscription`,
      {
        accountId: userid
      },
      getHeaderAI(token)
    )
      .catch(errorHandler);
  },

  changeSubscription: async (dataKey, subscriptionId, email, token = getToken()) => {
    return await axios
    .post(
      `${process.env.REACT_APP_SERVER_HOST_AI_OTHER}/dev/updatesubscription`,
      {
        dataKey: dataKey,
        subscriptionId: subscriptionId,
        userEmail: email
      },
      getHeaderAI(token)
    )
      .catch(errorHandler);
  }

};

export default recurringPayment;
